import React, { Component } from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { withStyles } from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import ChargeBadge from "./chargebadge";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import PubSub from "../../pubsub";
import Continuum from "../../clients/continuum";
import { JQ, isEmpty } from "../../utils/helpers";
import { green, grey, deepOrange } from "@material-ui/core/colors";
import LinearIcon from "@material-ui/icons/PlaylistPlay";

const cardHeight = 270;
const cardWidth = 260;

var instances = [];
//qid to contents
var CACHEMAP={}
function addInstance(obj) {
  instances.push(obj);
}
/*
function refreshAll() {
  console.log("videoList refreshAll");
  for (var key in instances) {
    instances[key].refresh();
  }
}
*/

const styles = theme => ({
  subheading: {
    margin: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  feature_container: {
    flexWrap: "nowrap",
    overflow: "hidden",
    marginBottom: theme.spacing.unit * 5
  },
  feature_list: {
    flexWrap: "nowrap",
    overflow: "hidden",
    justifyContent: "flex-start",
    '&:hover': {
      overflowX: "auto",
    },
  },
  feature_title: {
    flexGrow: 0,
    fontWeight: 400,
    fontSize: "0.8em"
  },
  feature_description: {
    color: "grey",
    fontWeight: 200,
    fontSize: "0.7em",
    flexGrow: 1
  },
  card_controls: {
    // position: "absolute",
    //bottom: 0,
    flexGrow: 0
  },
  imageCard: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    overflow: "hidden",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    //alignItems: "stretch",
    height: cardHeight,
    maxWidth: cardWidth,
    minWidth: cardWidth,
    margin: theme.spacing.unit,
    backgroundColor: "rgba(0,0,0,.02)"
  },
  cardContainer: {
    display: "flex",
    position: "relative"
  },
  cover: {
    objectFit: "cover",
    alignItems: "center",
    width: "100%",
    flexGrow: 0
  },
  row: {
    display: "flex",
    flexWrap: "nowrap",
    overflow: "hidden"
  },
  verified: {
    color: green[600]
  },
  unverified: {
    color: grey[300]
  },
  failedVerification: {
    color: deepOrange[600]
  },
  expand: {
    marginLeft: 'auto',
  },
  liveType: {
    position: "absolute",
    width: 32,
    height: 32,
    padding: theme.spacing.unit * 0.5,
    alignItems:"center",
    objectFit: "contain",
    top: 0,
    left: 0,
    float: "left",
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main
  },
  linearType: {
    position: "absolute",
    width: 30,
    height: 30,
    padding: theme.spacing.unit * 0.5,
    alignItems:"center",
    objectFit: "contain",
    top: 0,
    left: 0,
    float: "left",
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main
  },
});

class VideoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      qid: props.qid,
      channelObj: props.channelObj,
      contents: props.contents ? props.contents : [],
      showEmpty: props.showEmpty,
      showButtons: props.showButtons,
      refresh: true
    };

    this.isFetching = false;
    addInstance(this);
    PubSub.subscribe(this, PubSub.TOPIC_NEED_REFRESH, (msg, data) => {
      console.log("VideoList TOPIC_NEED_REFRESH received");
      this.refresh();
    });

    if(this.state.contents.length === 0 && !isEmpty(CACHEMAP[this.state.qid])){
      this.state.contents = CACHEMAP[this.state.qid].contents;
      this.state.title = CACHEMAP[this.state.qid].title;
    }else{
      this.refresh();
    }

    // console.log("VideoList constructor: " + JQ(this.state));

  }
  refresh = async () => {
    console.log("VideoList refresh hashMounted: " + this.hasMounted() + " isFetching: " + this.isFetching);
    if(!this.hasMounted() || this.isFetching){
      return;
    }
    this.isFetching = true;
    console.log("VideoList Refresh " + this.props.qid);
    try{
      await this.getContents(this.props.qid);
    }catch (err) {
      console.error("Could not fetch channel data: " + JSON.stringify(err));
      return;
    }
    this.isFetching = false;
  };

  getContents = async channelId => {
    console.log("VideoList getContents for " + channelId);
    if (channelId) {
      let data = null;
      try {
        data = await Continuum.contentData.getChannel(channelId);
        console.log("channel data refreshed: " + JSON.stringify(data));
      } catch (err) {
        console.error("Could not fetch channel data: " + JSON.stringify(err));
        return;
      }

        let contents = [];
        let title = data.meta.name;
        if (data.meta.contents !== undefined && data.meta.contents.length > 0) {
          for (var k=0;k<data.meta.contents.length;k++) {
            console.log("VideoList getContents stored: " + JQ(data.meta.contents));
            let stored = data.meta.contents[k];
            let obj = null;
            try {
              console.log("VideoList getContents stored: " + JQ(stored));
              obj = await Continuum.contentData.getByHash(stored);
            } catch (err) {
              console.error("Could not fetch object data: " + JSON.stringify(err));
              continue;
            }
            //console.log("GetByHash: Obj");
            if (obj) {
              /*
              let offeringInfo = null;
              try {
                offeringInfo = await Continuum.getOfferingContractInfo(
                  obj.id
                );
              } catch (err) {
                console.error("Could not fetch offering info: " + JSON.stringify(err));
                continue;
              }

              if (offeringInfo != null) {
                obj.meta.offeringInfo = offeringInfo;
                console.log("Found offeringInfo " + JQ(offeringInfo));
              }

              //Takes too long
              
              let verification = await Continuum.verifyContentObject(obj.hash);
              if (verification != null) {
                obj.meta.verification = verification;
                //console.log("VERIFICATION " + JQ(obj.meta.verification));
              }
              */

              contents.push(obj);
            }
          }
        }

        CACHEMAP[channelId] = {
          contents: contents,
          title: title,
          channelObj: data,
          refresh: false
        };

        if(this.hasMounted()){
          this.setState(CACHEMAP[channelId]);
        }
        console.log("channel contents found: " + contents.length);
    } else {
      console.log("No channel qid.");
    }
  };

  componentWillMount() {
    //console.log("VideoList componentWillMount");
  }

  componentDidMount() { 
    this._ismounted = true;
    this.refresh();
  }
  
  componentWillUnmount() {
    console.log("VideoList componentWillUnMount");
    this._ismounted = false;
  }

  hasMounted(){return this._ismounted === true;}
  
  /*
  shouldComponentUpdate(nextProps, nextState){
    let { contents } = this.state;
    let { nextContents } = nextState;

    let shouldRender = contents !== nextContents;

    //console.log(`VideoList shouldComponentUpdate: ${contents.length}`);
    return contents.length === 0 || shouldRender;
  }
*/
  handleUpload = () => {
    let obj = {
      channelId: this.state.qid
    };
    PubSub.publish(PubSub.TOPIC_OPEN_UPLOAD, obj);
  };

  handleImport = () => {
    let obj = {
      channelId: this.state.qid
    };
    console.log("publish: " + JSON.stringify(obj));
    PubSub.publish(PubSub.TOPIC_OPEN_IMPORT, obj);
  };

  handleChannelDelete = async () => {
    try{
      await Continuum.deleteChannel({channelId:this.state.qid});
      this.refresh();
      console.log("Successfully deleted channel: " + this.state.qid);
    }catch(e){
      console.error("Error deleting channel: " + this.state.qid);
    }
  };

  onInfoClicked = (obj) =>{
    console.log("onInfoClicked - " + JQ(obj));
    if(!isEmpty(obj)){
      PubSub.publish(PubSub.TOPIC_OPEN_JSONVIEW, obj);
    }
  }

  render() {
    let state = this.state;

    let { classes, title, qid, showEmpty, contents, showButtons } = this.props;

    if (isEmpty(contents)) {
      contents = this.state.contents;
    }

    if (!title) {
      title = state.title;
    }

    let { channelObj } = state;

    if (isEmpty(channelObj) && contents.length === 0) {
      if (!isEmpty(CACHEMAP[qid]) && !isEmpty(CACHEMAP[qid].contents)){ 
        contents = CACHEMAP[qid].contents;
        channelObj = CACHEMAP[qid].channelObj;
      }
    }

    if (!showEmpty && contents.length === 0) {
      return "";
    }

    let that = this;

    function ContentCard({ obj }) {
      if (isEmpty(obj) || !obj.meta || isEmpty(obj.meta)) {
        return "";
      }
      let offeringInfo = obj.meta.offeringInfo;
      let mandatory = false;
      let accessCharge = 0.0;

      if (!isEmpty(obj.meta.access_charge)) {
        accessCharge = parseFloat(obj.meta.access_charge);
      }

      if (!isEmpty(offeringInfo)) {
        mandatory = offeringInfo.mandatorySponsoring;
      }

      return (
        <Card key={obj.id} className={classes.imageCard}>
          <CardActionArea
            component={Link}
            to={{ pathname: "/playerpage", state: { obj: obj } }}
          >
            <CardMedia
              component="img"
              alt="Video Poster"
              className={classes.cover}
              height="150"
              image={obj.meta.imageUrl ? obj.meta.imageUrl : ""}
              title="Video"
            >
            </CardMedia>
          </CardActionArea>
          <CardContent>
          <Typography noWrap gutterBottom variant="h5" component="h2" className={classes.feature_title}>
              {obj.meta.asset_metadata ? obj.meta.asset_metadata.title: obj.meta.name}
          </Typography>
          <Typography noWrap component="p" className={classes.feature_description}>
              {obj.meta.asset_metadata ? obj.meta.asset_metadata.synopsis: obj.meta.description}
          </Typography>
          </CardContent>

          <CardActions className={classes.card_controls}>
            <IconButton aria-label="Add to favorites">
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="Share">
              <ShareIcon />
            </IconButton>
            <div className={classNames(classes.expand)}>
              <IconButton aria-label="Info" color="primary"
                onClick={e => {that.onInfoClicked(obj)}}
              >
                <InfoIcon />
              </IconButton>
            </div>
          </CardActions>
          <ChargeBadge mandatory={mandatory} accessCharge={accessCharge} />
          {PlayableIcon({obj,classes})}
        </Card>
      );
    }

    function PlayableIcon({obj, classes}){
      if(Continuum.isLive(obj)){
        return (
          <svg className={classes.liveType} height="597pt" viewBox="-18 -128 597.33331 597" width="597pt" xmlns="http://www.w3.org/2000/svg"><path d="m560 22.832031c0-16.566406-13.433594-30-30-30h-500c-16.566406 0-30 13.433594-30 30v280c0 16.570313 13.433594 30 30 30h500c16.566406 0 30-13.429687 30-30zm-410 230h-80c-5.523438 0-10-4.476562-10-10v-160c0-5.519531 4.476562-10 10-10s10 4.480469 10 10v150h70c5.523438 0 10 4.480469 10 10 0 5.523438-4.476562 10-10 10zm60-10c0 5.523438-4.476562 10-10 10s-10-4.476562-10-10v-160c0-5.519531 4.476562-10 10-10s10 4.480469 10 10zm159.371094-156.488281-60 160c-1.464844 3.90625-5.199219 6.496094-9.371094 6.488281h-.277344c-4.269531-.117187-7.996094-2.921875-9.273437-7l-50-160c-1.65625-5.273437 1.273437-10.894531 6.550781-12.550781 5.273438-1.652344 10.894531 1.277344 12.550781 6.554688l41.308594 132.207031 49.769531-132.738281c1.933594-5.175782 7.695313-7.804688 12.871094-5.871094s7.804688 7.695312 5.871094 12.871094zm100.628906 66.488281c5.523438 0 10 4.480469 10 10 0 5.523438-4.476562 10-10 10h-50v60h70c5.523438 0 10 4.480469 10 10 0 5.523438-4.476562 10-10 10h-80c-5.523438 0-10-4.476562-10-10v-160c0-5.519531 4.476562-10 10-10h80c5.523438 0 10 4.480469 10 10 0 5.523438-4.476562 10-10 10h-70v60zm0 0"/>
          </svg>
        );
      }else if(Continuum.isLinear(obj)){
        return (
          <LinearIcon color="primary" className={classes.liveType}/>
        );
      }
      return "";
    }

    function ContentRow({ heading, lib }) {
      let account = Continuum.getAddress();
      return (
        <div className={classes.feature_container}>
          <Typography
            noWrap
            variant="h5"
            className={classNames(classes.subheading, classes.margins)}
          >
            {heading}
          </Typography>
          {showButtons && channelObj != null && channelObj.meta.owner === account ? (
            <>
              <Button
                color="primary"
                className={classes.button}
                onClick={() => that.handleUpload()}
              >
                Upload
              </Button>
              <Button
                color="primary"
                className={classes.button}
                onClick={() => that.handleImport()}
              >
                Import
              </Button>
              <Button
                color="primary"
                className={classes.button}
                onClick={() => that.handleChannelDelete()}
              >
                Delete
              </Button>
            </>
          ) : (
            ""
          )}

          <GridList justify="flex-start" className={classes.feature_list}>
            {lib &&
              lib.map((obj, index) => <ContentCard key={index} obj={obj} />)}
          </GridList>
        </div>
      );
    }
    return <ContentRow key={this.props.qid} heading={title} lib={contents} />;
  }
}

export default withStyles(styles)(VideoList);
