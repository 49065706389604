import React, { Component } from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import VideoList from '../contentviews/videolist'
import PubSub from '../../pubsub'
import {isEmpty} from '../../utils/helpers'
import Continuum from '../../clients/continuum'
import Accounts from "../../storage/accounts"

const headerHeight = 400;

const styles = theme => ({
  header: {
    position: "absolute",
    display: "flex",
  },
  headerContainer: {
    display: "flex",
    marginBottom: theme.spacing.unit*5,
  },
  headerBackground: {
    height:headerHeight,
  },
  margins: {
    margin: theme.spacing.unit*2,
    paddingTop: theme.spacing.unit*4,
  },
  noMargins: {
    margin: 0,
    padding: 0,
  },
  spacing: {
    marginTop:theme.spacing.unit*2,
    marginBottom:theme.spacing.unit*2
  },
  avatar: {
    margin: 10,
    justifyContent:"center",
    verticalAlign: "middle",
    alignContent:"center",
  },
  bigAvatar: {
    marginTop: theme.spacing.unit,
    marginBottom:theme.spacing.unit, 
    marginLeft: theme.spacing.unit*5,
    width: 64,
    height: 64,
  },
  title: {
    //color: "black",
    fontSize: "2em",
    fontWeight: 400,
    width: "100%"
  },
  channeltitle: {
    flexGrow: 1,
    marginTop: theme.spacing.unit,
    color: "grey",
    fontSize: "1em",
  },
  container: {
    position: "relative",
    height: 200,
  },
  maincontents: {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"flex-start",
    flexDirectxion: "colum",
    paddingTop: theme.spacing.unit*5,
    paddingBottom: theme.spacing.unit*5,
    overflow: "auto"
  },
  gridChannel: {
    display:"flex",
    flexFlow: "column",
    alignItems:"flex-start",
    textAlign: "left",
    minWidth: 250,
    maxWidth: 250,
    flexGrow: 0,
  },
  gridVideo: {
    float: "right",
    transform: 'translateZ(0)',
    flexGrow: 1,
  },
  gridItem: {
    marginLeft: theme.spacing.unit*2,
  },
  featureSpace: {
    marginLeft: theme.spacing.unit,
  },
  verticleSeparator: {
    borderRight: "1px solid rgba(100,100,100,.3)",
    marginTop: theme.spacing.unit*10,
    float:"right",
    height: 100
  },
  buttonContainer: {
    display: 'flex',
    marginRight: theme.spacing.unit*5,
    justifyContent: "flex-end",
    height: 60,
    textFlow: "nowrap"
  },
  button: {
    marginLeft:theme.spacing.unit,
    marginRight:theme.spacing.unit,
    backgroundColor: fade(theme.palette.common.black, 0.3),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.5),
    },
    color: theme.palette.common.white,
    whiteSpace: "nowrap"
  },
  coverImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    objectFit: "cover",
    backgroundColor:fade(theme.palette.primary.main, 0.8)
  },
  channelButton: {
    height: "100%",
    textAlign: "left",
    marginLeft: theme.spacing.unit*2,
    fontSize: "x-small"
  },
  feature_container: {
    flexWrap: 'nowrap',
    overflow: "hidden",
  },
  feature_list: {
    flexWrap: 'nowrap',
    overflowY: "hidden",
    overflow: "auto",
  },
  feature_title: {
    color: theme.palette.primary.light,
  },
  feature_titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  channel_list: {
    flexWrap: 'nowrap',
    height: 300,
    overflow: "auto",
  },
  cover: {
      objectFit: "cover",
      alignItems: 'center',
      width:"100%",
      height:"100%",
  },
  row: {
    marginTop: theme.spacing.unit*2,
    display:"flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width:"100%"
  },
  paper: {
    minHeight: 400
  },
  column: {
      display:"flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignItems: "space-between",
      padding:theme.spacing.unit,
  }
});

class Home extends Component {

  constructor(props){
    super(props);
    const account = Continuum.getCurrentAccount();
    let defaultChannel = account.defaultChannel;
    this.state = {
      account: account,
      title:account? `${account.name}'s Page`: "",
      defaultChannel: null,
      selectedChannelId: defaultChannel ? defaultChannel : "",
      channels: account.publishedChannels,
      featured_videos: []
    };

    PubSub.subscribe(this, PubSub.TOPIC_NEED_REFRESH,(msg,data) =>{
      console.log("HOME: refresh");
      const account = Continuum.getCurrentAccount();
      Continuum.getChannels().then(results =>{
        this.setState({
          account:account,
          channels:results});
      });
    });
  }

  handleNewChannel = event => {
    PubSub.publish(PubSub.TOPIC_OPEN_NEWCHANNEL,this.state.selectedChannelId);
  }

  componentWillMount() {
    console.log("HOME componentWillMount");
    /*
    Continuum.getMyContent().then(results =>{
      this.setState({featured_videos:results});
    });
  */

  const account = Continuum.getCurrentAccount();
  Continuum.getChannels().then(results =>{
    this.setState({
      account:account,
      channels:results});
  });
    
  }

  componentDidMount() {
    console.log("HOME componentDidMount");
  }

  render() {
    const { classes } = this.props;
    let {channels} = this.state;
    console.log("HOME render");

    return (
      <div>
          <Paper className={classNames(classes.paper, classes.column)}>
            {channels? channels.map((lib, index) => {
              if(isEmpty(lib)){
                return "";
              }
              return(
                <VideoList key={index} qid={lib.id} channelObj={lib} showEmpty={false} showButtons={false}/>
              );
            }) : ""}
          </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(Home);