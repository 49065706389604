import React from 'react';
import { render } from 'react-dom';
import App from './components/App';
//import * as serviceWorker from './serviceWorker';
import {HashRouter} from 'react-router-dom'

render((
    <HashRouter>
        <App/>
    </HashRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();

if (module.hot) module.hot.accept();