import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SettingsIcon from '@material-ui/icons/Settings';
import DevicesIcon from '@material-ui/icons/Devices';
import FavoriteIcon from '@material-ui/icons/Favorite';
import RecentIcon from '@material-ui/icons/PlaylistPlay';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import PurchasedIcon from '@material-ui/icons/AttachMoney';
import MyChannelsIcon from '@material-ui/icons/VideoLibrary';
import PreferencesIcon from '@material-ui/icons/Person';

import PubSub from '../../pubsub'

const drawerWidth = 210;
const drawerWidthMinimized = 60;
const styles = theme => ({
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidthMinimized,
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow:"hidden"
  },

  drawerOpen: {
    width: drawerWidth,
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    })
  },

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  listText: {
    whiteSpace: "nowrap"
  }
});

class SideBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: this.props.appState.openSidebar,
    };

    PubSub.subscribe(this,PubSub.TOPIC_VIEW_LIST_TOGGLE,(msg,data)=>{
      this.setState({open: !this.state.open});
    });
  }

  handleMouseEnter = (event) => {
    const {open} = this.state;
    if(!open){
      PubSub.publish(PubSub.TOPIC_VIEW_LIST_TOGGLE);
      this.tempOpen = true;
    }
    
  }

  handleMouseLeave = (event) => {
    if(this.tempOpen){
      PubSub.publish(PubSub.TOPIC_VIEW_LIST_TOGGLE);
      this.tempOpen = false;
      return;
    }
  
  }

  render() {
    const {classes} = this.props;
    const {open} = this.state;

    function NavListItem({label, to, Icon, activeOnlyWhenExact }) {
      return (
        <Route
          path={to}
          exact={activeOnlyWhenExact}
          children={({ match }) => (
            <ListItem button
              component={Link} to={to}
              selected= {match!==null}
              key={label}>
              {
                Icon !== undefined &&
                <ListItemIcon><Icon /></ListItemIcon>
              }
              <ListItemText className={classes.listText} primary={label} />
            </ListItem>
          )}
        />
      );
    }
    
    return (
      <Drawer
        variant="persistent"
        anchor="left"
        open={true}
        className={open? classes.drawerOpen:classes.drawer}
        classes={{paper:open? classes.drawerOpen:classes.drawer}}
      >
      <div       
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
      <div className={classes.drawerHeader} />
        <div className={classes.toolbar} />
        <List>
            <NavListItem activeOnlyWhenExact={true} to="/" label="Home" Icon={HomeIcon} />
            <NavListItem to="/wallet" label="Wallet" Icon={WalletIcon} />
            <NavListItem to="/preferences" label="Preferences" Icon={PreferencesIcon} />
            <NavListItem to="/devices" label="Devices" Icon={DevicesIcon}/>
            <Divider className={classes.divider}/>
            <NavListItem to="/mypage" label="My Sites" Icon={MyChannelsIcon}/>
            <NavListItem to="/favorites" label="Favorites" Icon={FavoriteIcon}/>
            <NavListItem to="/recordings" label="My Recordings" Icon={RecentIcon}/>
            <NavListItem to="/purchased" label="Purchased" Icon={PurchasedIcon}/>
            <NavListItem to="/subscriptions" label="Subscriptions" Icon={SubscriptionsIcon}/>
            <NavListItem to="/settings" label="Settings" Icon={SettingsIcon} />
        </List>
        </div>
      </Drawer>
    );
  }
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles, { withTheme: true })(SideBar);